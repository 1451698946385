<template>
  <div class="agb">
    <h2 class="my-4">Datenschutz</h2> 
    <p>
      Der Schutz Ihrer personenbezogenen Daten ist uns wichtig. <br><br>
      Name und Anschrift der verantwortlichen Stelle: <br>
      Frank Schröpfer <br>
      Anemonenweg 7 <br>
      97846 Partenstein
    </p>
    
    <h3>Zweckbestimmung und Datensparsamkeit</h3> 
    <p>
      Auf schroepfer-net.de erhalten Sie Informationen über die Bücher des Autors Frank Schröpfer. Wir erheben und verwenden 
Ihre personenbezogenen Daten ausschließlich im Rahmen der Bestimmungen der Verordnung zum Datenschutzrecht der Europäischen Union 
und der Bundesrepublik Deutschland. Im Folgenden unterrichten wir Sie über Art, Umfang und Zwecke der Erhebung und Verwendung personenbezogener Daten. 
Eine darüberhinausgehende Verarbeitung zu anderen Zwecken als den nachfolgend bestimmten erfolgt nicht. Sie können diese Unterrichtung 
jederzeit von dieser Webseite abrufen.
    </p>
    <h3>Datenübermittlung und -protokollierung zu systeminternen und statistischen Zwecken</h3> 
    <p>
      Ihr Internet-Browser übermittelt beim Zugriff auf unsere Webseite aus technischen Gründen automatisch Daten an unseren Webserver. 
Es handelt sich dabei unter anderem um Datum und Uhrzeit des Zugriffs, URL der verweisenden Webseite, abgerufene Datei, Menge der gesendeten Daten, 
Browsertyp und -version, Betriebssystem sowie Ihre IP-Adresse. Diese Daten werden getrennt von anderen Daten, die Sie im Rahmen der 
Nutzung unseres Angebotes eingeben, gespeichert. Eine Zuordnung dieser Daten zu einer bestimmten Person ist uns nicht möglich. 
Diese Daten werden zu statistischen Zwecken ausgewertet und im Anschluss gelöscht.
    </p>

    <h3>Nutzungsdaten</h3> 
    <p>
Wir erheben und verwenden personenbezogene Daten von Ihnen, soweit dies erforderlich ist, um die Inanspruchnahme unseres Internetangebotes 
zu ermöglichen oder abzurechnen (Nutzungsdaten). Dazu gehören insbesondere Merkmale zu Ihrer Identifikation und Angaben zu Beginn und Ende 
sowie des Umfangs der Nutzung unseres Angebotes.

Für Zwecke der Werbung, der Marktforschung und zur bedarfsgerechten Gestaltung unseres Internetangebotes dürfen wir bei Verwendung 
von Pseudonymen Nutzungsprofile erstellen. Sie haben das Recht, dieser Verwendung Ihrer Daten zu widersprechen. Die Nutzungsprofile dürfen 
wir nicht mit Daten über den Träger des Pseudonyms zusammenführen.
    </p>

    <h3>Betroffene Personen und Datenkategorien</h3> 
    <p>
      Betroffen sind Kunden, Besucher der Website, Lieferanten und Geschäftspartner, Autoren sowie Mitarbeiter. Hier werden Name, Vorname, 
gegebenenfalls Adresse, IP und Bezahldaten erfasst. Allerdings nur im Umfang, wie unter Zweckbestimmung und Datensparsamkeit beschrieben.
    </p>

    <h3>Rechtsgrundlage</h3> 
    <p>
      Rechtsgrundlage für die Speicherung, Verarbeitung und Weitergabe Ihrer Daten ist zum einen Ihre Einwilligung (z.B. bei Newslettern), 
eine Interessenabwägung, die in der Abwägung der Interessen keine Einschränkung Ihrer Rechte und Freiheiten ergab oder im Falle eines 
Kaufs der Kaufvertrag mit Ihnen.
    </p>

    <h3>Löschung von Daten</h3> 
    <p>
      Fällt der Zweck weg und besteht auch keine gesetzliche Aufbewahrungsfrist, werden die entsprechenden personenbezogenen Daten gelöscht. 
Grundsätzlich werden die Daten nur für die Dauer der oben genannten Zwecke gespeichert.
    </p>

    <h3>Empfänger oder Kategorien von Empfängern, denen die Daten mitgeteilt werden können</h3> 
    <p>
      Ihre Daten werden zum Zwecke der Bestellabwicklung an den Postversender übergeben. Zudem werden Daten beim Bezahlen an den entsprechenden 
Bezahldienst weitergeleitet.  Wir sind zudem verpflichtet aufgrund verschiedener gesetzlicher Vorschriften die Daten an das Finanzamt 
oder andere Behörden weiterzuleiten.
    </p>

    <h3>Datenübermittlung an Drittstaaten</h3> 
    <p>
      Eine Datenübermittlung an Drittstaaten ist nicht angedacht und findet nicht statt.
    </p>
    <h3>
      Betroffenenrechte
    </h3>
    <p>Sie haben jederzeit die Möglichkeit und das Recht sich bei der verantwortlichen Stelle (siehe oben) zu melden und Auskunft darüber zu verlangen, 
    wie Ihre Daten verarbeitet werden. Zudem haben Sie ein Recht auf Berichtigung Ihrer Daten. Sie haben das Recht auf Einschränkung der Verarbeitung, 
    also nur noch eine Verarbeitung von Teilinformationen zuzulassen. Zudem haben Sie jederzeit das Recht der Verarbeitung Ihrer personenbezogenen 
    Daten zu widersprechen. Der Verlag prüft dann, inwieweit der Nichtverarbeitung gesetzliche Weitergabe- und Verarbeitungspflichten entgegenstehen 
    und informiert Sie entsprechend. Sie haben ein Recht auf Datenportabilität, d.h. dass wir Ihnen Ihre personenbezogenen Daten in maschinenlesbarer 
    Form in einem gängigen von uns bestimmten Format auf Anforderung übergeben.</p>
    <p>
    Zudem haben Sie ein Beschwerderecht bei der für Ihr Land zuständigen Aufsichtsbehörde für den Datenschutz, wenn Sie der Meinung sind, 
    dass wir mit Ihren personenbezogenen Daten nicht ordnungsgemäß und im Sinne dieser Erklärung umgehen.
    </p>
    <p>
    Haben Sie uns Ihre Einwilligung zur Verarbeitung Ihrer Daten gegeben, können Sie diese Einwilligung jederzeit widerrufen.
    </p>
    <p>
    Erhalten Sie von uns Werbung zugesendet, so steht Ihnen auch hierfür jederzeit das Widerspruchsrecht zu, keine Werbung mehr von uns zu erhalten.
    </p>
    <h3>
      Cookies
    </h3>
    <p>
      Diese Website verwendet Cookies. Die Website-Cookies sind kleine Dateien, die es ermöglichen, auf dem Zugriffsgerät der Nutzer (PC, Smartphone o.ä.) 
spezifische, auf das Gerät bezogene Informationen zu speichern. Sie dienen zum einem der Benutzerfreundlichkeit von Webseiten und damit den Nutzern 
(z.B. Speicherung von Logindaten). Zum anderen dienen sie, um die statistische Daten der Webseitennutzung zu erfassen und sie zwecks Verbesserung 
des Angebotes analysieren zu können. Die Nutzer können auf den Einsatz der Cookies Einfluss nehmen. Die meisten Browser verfügen eine Option,
mit der das Speichern von Cookies eingeschränkt oder komplett verhindert wird. Allerdings wird darauf hingewiesen, dass die Nutzung und insbesondere 
der Nutzungskomfort ohne Cookies eingeschränkt werden.
    </p>
  </div>
</template>
<script>

export default {
  name: 'Impressum',
  data() {
    return {
    }
  },
  computed: {
    year: function () {
      return new Date().getFullYear()
    }
  },
}
</script>
